export interface IProductKeys {
    ilevel: string,
    compliance: string,
    subscriptions: string;
    companyportal: string
}

export const ProductKeys: IProductKeys = {
    ilevel: "ilevel",
    compliance: "compliance",
    subscriptions: "subscriptions",
    companyportal: "company-portal"
}