import axios, { AxiosResponse } from "axios";
import { Manifest, Product, UnionConfig } from "models";
import { setUnionConfig } from "redux/features/unionconfig";
import { interceptor } from "services/interceptor";
import store from "store";

export const loadUnionConfig = async () =>
  await fetch(window.location.origin + "/assets/union-config/" + window.location.hostname + ".json").then(
    (response) => {
      response.json().then(async (unionConfig: UnionConfig) => {
        interceptor(unionConfig);
        let manifestUrl;
        if (unionConfig.manifestFromApi) {
          manifestUrl = getManifestApiUrl(unionConfig);
        } else {
          manifestUrl = getManifestLocalUrl(unionConfig);
        }

        try {
          await fetchManifest(manifestUrl, unionConfig, false);
        }
        catch (error) {
          //If UMO fails, trying to fetch the manifest from local, and use the data provided by the integrated applications (old way).
          if (unionConfig.manifestFromApi) {
            await fetchManifest(getManifestLocalUrl(unionConfig), unionConfig, true);
          }
        }
      }
      );
    });

const getManifestApiUrl = (unionConfig: UnionConfig) => unionConfig.apiUrl + "/manifest";
const getManifestLocalUrl = (unionConfig: UnionConfig) => window.location.origin + "/assets/union-config/manifest/" + unionConfig.manifest;

const fetchManifest = (manifestUrl: string, unionConfig: UnionConfig, fallback: boolean) => {
  return axios.get(manifestUrl).then(
    (value: AxiosResponse<Manifest, any>) => {
      unionConfig.manifest = value.data;

      if (unionConfig.sandboxEnabled && localStorage.manifest) {
        unionConfig.manifest = JSON.parse(localStorage.manifest);
      }

      const environments = JSON.parse(localStorage.getItem("environments") || "{}");

      const hiddenProductKeys: string[] = [];
      unionConfig.manifest.forEach((product: Product) => {
        try {
          if (!product.environment) {
            if (environments[product.key] && product.environments[environments[product.key]]) {
              product.environment = product.environments[environments[product.key]];
            } else if (unionConfig.defaultEnvironment && product.environments[unionConfig.defaultEnvironment]) {
              product.environment = product.environments[unionConfig.defaultEnvironment];
            }
            else {
              product.environment = product.environments[Object.keys(product.environments).reverse()[0]];
            }
          } else {
            if (environments[product.key] && product.environment.name.toLocaleLowerCase() !== environments[product.key]) {
              product.environment = product.environments[environments[product.key]];
            }
          }

          if (product.environment.isVisible === false) {
            hiddenProductKeys.push(product.key);
          }
        }
        catch (error) {
          console.error(error);
        }
      });
      unionConfig.manifest = unionConfig.manifest.filter(product => hiddenProductKeys.indexOf(product.key) === -1);

      if (unionConfig.sandboxEnabled && localStorage.manifest) {
        localStorage.setItem("manifest", JSON.stringify(unionConfig.manifest));
      }

      /*In case of fallback (when UMO fails), set the manifestFromApi to false
       to use the data provided by the integrated applications (old way).*/
      if (fallback) {
        unionConfig.manifestFromApi = false;
      }
      store.dispatch(setUnionConfig(unionConfig));
    },
    (reason: any) => { throw new Error(reason) }
  );
}