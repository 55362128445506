import { Home } from "components/home";
import { Layout } from "components/layout";
import { Logout } from "components/logout/logout";
import { PageNotFound } from "components/pageNotFound";
import RegionSelection from "components/region-selection/region-selection";
import { AllLastVisitedPagesSkeleton, SandboxSkeleton } from "components/skeletons";
import { TenantSelection } from "components/tenant-selection/tenant-selection";
import { UnauthorizedScreen } from "components/unauthorized";
import { RouteObject, useRoutes } from "react-router-dom";
import { useAppSelector } from "redux/hooks";

export const AppRoutes = () => {
  const productRoutes = useAppSelector((state) => state.route.routes);
  const unionRoutes: RouteObject[] = [
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: "/sandbox",
          element: <SandboxSkeleton />,
        },
        {
          path: "/tenant-selection",
          element: <TenantSelection />,
        },
        {
          path: "/region-selection",
          element: <RegionSelection />,
        },
        {
          path: "/logout",
          element: <Logout />,
        },
        {
          path: "/lastVisited/:productkey",
          element: <AllLastVisitedPagesSkeleton />,
        },
        {
          path: "/unauthorise",
          element: <UnauthorizedScreen />,
        },
        {
          path: "*",
          element: <PageNotFound />,
        },
      ],
    },
  ];

  return useRoutes([...unionRoutes, ...productRoutes]);
};
