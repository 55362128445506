import { Menu, MenuItem, Product, ProductRouteData, UnionConfig } from "models";
import { setMenu } from "redux/features/menu";
import { httpGet } from "services/http/http.service";
import { PathHelper } from "services/path-helper";
import { getRouteByLocalPath } from "services/router-helper";
import store from "store";

export async function loadMenu(unionConfig: UnionConfig): Promise<any> {
  let menu: MenuItem[] = [];

  if (!unionConfig.manifest) {
    return;
  }

  const names = unionConfig.manifest.map((p) => p.name);
  await Promise.allSettled(
    unionConfig.manifest.map(async (productManifest) => {
      try {
        let response: MenuItem[];
        const forceManifestFromProduct = unionConfig.forceManifestFromProduct.indexOf(productManifest.key) > -1;
        if (unionConfig.manifestFromApi === true && forceManifestFromProduct === false) {
          response = JSON.parse(JSON.stringify(productManifest.environment.apiEndpoints.menuData)) as MenuItem[];
        }
        else {
          const url = PathHelper.isAbsolutePath(productManifest.environment.apiEndpoints.menu)
            ? productManifest.environment.apiEndpoints.menu
            : (productManifest.environment.apiRootUrl || productManifest.environment.appRootUrl) +
            "/" +
            productManifest.environment.apiEndpoints.menu;

          response = await httpGet(url);
        }

        if (response) {
          menu = combineMenu(response, menu, names, productManifest);
        }
      } catch (e) {
        console.error(e);
      }
    }));
  store.dispatch(setMenu(menu));
}

function combineMenu(response: MenuItem[], menu: MenuItem[], names: string[], product: Product) {
  parseMenu(response, product.key);
  const topLevel: MenuItem = {
    productKey: product.key,
    label: product.name,
    iconClass: product.icon,
    items: applyRoutingRules(response),
  };
  if (product.shortName) {
    topLevel.shortLabel = product.shortName;
  }
  const menus = [...menu, topLevel];
  menus.sort((a: { label?: string }, b: { label?: string }) =>
    names.indexOf(a.label) < names.indexOf(b.label) ? -1 : 1
  );
  return menus;
}

function parseMenu(menu: Menu, productKey: string) {
  menu.forEach((menuItem) => {
    menuItem.productKey = productKey,
      menuItem.abbreviation = menuItem.abbreviation || getAbbreviation(menuItem.label)

    if (menuItem.path !== undefined) {
      menuItem.path = `/${productKey}/${menuItem.path}`.replace("//", "/");
    }

    if (menuItem.items && menuItem.items.length > 0) {
      parseMenu(menuItem.items, productKey);
    }
  });
}

function getAbbreviation(label: string): string {
  const letters = label
    .replace(/\/\\&\\-/g, " ")
    .match(/(?:^|[\s])([A-z])/g)
    .map((x) => x.trim());
  return letters.length > 1 ? letters[0] + letters[letters.length - 1] : letters[0];
}

function applyRoutingRules(menu: Menu): Menu {
  const results: Menu = [];

  const routeMap = store.getState().route.routeMap;
  const unionConfig = store.getState().unionConfig.unionConfig;

  menu.forEach((item) => {
    if (item.items) {
      item = Object.assign({}, item);
      item.items = applyRoutingRules(item.items);
    }

    if (item.path) {
      const route = getRouteByLocalPath<ProductRouteData>(item.path, routeMap);
      if (!route) {
        if (unionConfig.debugMode) {
          console.log("Unwired route: ", item.path);
        }
        return;
      }

      if (route) {
        if (route.permissions !== undefined && (item.permissions === undefined || item.permissions.length === 0)) {
          item.permissions = route.permissions;
        }

        if (route.permissionRequirement !== undefined && (item.permissionRequirement === undefined || item.permissionRequirement.length === 0)) {
          item.permissionRequirement = route.permissionRequirement;
        }

        if (route.popout !== undefined && item.popout === undefined) {
          item.popout = route.popout;
        }
      }
    }
    results.push(item);
  });

  return results;
}
