import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from "@fortawesome/pro-solid-svg-icons";
import axios from 'axios';
import { UnionLoader } from "components/shared/loader";
import { Timeout } from "components/timeout";
import { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { AppRoutes } from "router/routes";
import { loadProductRoutes } from "router/routes/router.services";
import { loadMenu } from "services/menu-builder/menu-builder.service";
import { loadPermission } from "services/permission/permission.service";
import { loadUnionConfig } from "services/union-config/union-config.service";
import { loadUser } from "services/user/user.service";
import "./app.css";
import "@fortawesome/fontawesome-pro/css/solid.css";

library.add(fas, far);
axios.defaults.timeout = 20000;

export function App() {
  const [loading, setLoading] = useState(true);
  const unionConfig = useAppSelector((state) => state.unionConfig.unionConfig);

  useEffect(() => {
    loadUser();
    loadUnionConfig();
  }, []);

  useEffect(() => {
    if (unionConfig.manifest) {
      const loadData = async () => {
        await Promise.allSettled([loadPermission(unionConfig), loadProductRoutes(unionConfig)]);
        loadMenu(unionConfig);
        setLoading(false);
      };
      loadData();
    }
  }, [unionConfig, unionConfig.manifest]);

  return (
    loading ?
      <UnionLoader /> :
      <>
        <AppRoutes />
        <Timeout id="user-idle-modal" />
      </>
  );
}