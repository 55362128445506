import { Button } from "@medm-ui/button";
import { UnionCard } from "components/shared/union-card";
import { CardRow, Page } from "models/last-visited-page/last-visited-page";
import React from "react";
import { useNavigate } from "react-router-dom";

const CardsRow = ({ entry, numberOfCards }: CardRow) => {
    const navigate = useNavigate();
    let cardClicked = false;

    const onCardClick = (page: Page) => {
        if (!cardClicked) {
            cardClicked = true;
            if (page.popout) {
                if (page.popout === true) {
                    window.open(page.url, "_blank");
                } else {
                    window.open(page.url, page.popout.target, page.popout.features);
                }
            } else {
                navigate(page.url, { replace: true });
            }
        } else {
            cardClicked = false;
        }
    };

    const onLastVisitedCardClick = (page: Page) => {
        if (!cardClicked) {
            cardClicked = true;
            if (page.popout) {
                if (page.popout === true) {
                    window.open(page.url, "_blank");
                } else {
                    window.open(page.url, page.popout.target, page.popout.features);
                }
            } else {
                navigate(
                    page.url.includes(window.location.origin)
                        ? page.url.replace(window.location.origin, "")
                        : page.url.replace("https://dev.finapps.ihsmarkit.com", "")
                );
            }
        } else {
            cardClicked = false;
        }
    }

    const getId = (page: Page) => page?.url?.includes("@") && page?.url?.includes("rfr-calculator")
        ? `${page.pageName} : ${page.url.split("/")[3]}`
        : page.pageName

    return (
        <React.Fragment>
            <div className="nameAndButtonSection" data-test-id="LVPRowNameAndButtonSection">
                <p className="lastVisitedAppName" id={entry.key} data-test-id={entry.key}>
                    {entry.key}
                </p>
                {entry.pages.length > numberOfCards && (
                    <Button
                        buttonId="homeSeeAllButton"
                        buttonLabel="See all"
                        buttonType="Tertiary"
                        widthSize="64px"
                        heightSize="24px"
                        className="seeAllButton"
                        buttonSize="small"
                        onButtonClick={() => {
                            navigate(`lastVisited/${entry.key}`, {
                                state: entry,
                            });
                        }}
                    />
                )}
            </div>
            <div className="lastVisitedPagesCards" data-test-id="lastVisitedPagesCardsRow">
                {entry.pages.length > numberOfCards
                    ? entry.pages.slice(0, numberOfCards).map((page: Page) => (
                        <UnionCard
                            id={getId(page)}
                            test-id={page.pageName}
                            width="320"
                            page={page}
                            isLastVisitedCard={true}
                            key={page.pageName + "-card"}
                            isClickable={true}
                            onCardClick={() => onCardClick(page)}
                        />
                    ))
                    : entry.pages.map((page: Page) => (
                        <UnionCard
                            id={getId(page)}
                            test-id={page.pageName}
                            width="320"
                            page={page}
                            isLastVisitedCard={true}
                            key={page.pageName + "-card"}
                            isClickable={true}
                            onCardClick={() => onLastVisitedCardClick(page)}
                        />
                    ))}
            </div>
        </React.Fragment>
    );
};

export default CardsRow;