import { Layout } from "components/layout";
import { AppRoute, Product, ProductRouteData, RouteData, RouteMap, UnionConfig } from "models";
import { RouteObject } from "react-router-dom";
import { setRoute } from "redux/features/routes";
import { PathHelper } from "services/path-helper";
import { getPathMatchRegex } from "services/router-helper";
import { ProtectedRoute } from "./protectedroute";
import store from "store";
import { AppViewerSkeleton } from "components/skeletons";
import { httpGet } from "services/http/http.service";

export async function loadProductRoutes(unionConfig: UnionConfig): Promise<any> {
  let routeMap: RouteMap[] = [];
  let route: RouteObject[] = [];

  if (!unionConfig.manifest) {
    return;
  }

  await Promise.allSettled(
    unionConfig.manifest.map(async (productManifest) => {
      try {
        let routeData: {
          parentRoute: AppRoute<ProductRouteData>,
          routeMap: RouteMap[]
        };
        const forceManifestFromProduct = unionConfig.forceManifestFromProduct.indexOf(productManifest.key) > -1;

        if (unionConfig.manifestFromApi && !forceManifestFromProduct) {
          routeData = parseRouting(productManifest, productManifest.environment.apiEndpoints.routesData);
        }
        else {
          const url = PathHelper.isAbsolutePath(productManifest.environment.apiEndpoints.routes)
            ? productManifest.environment.apiEndpoints.routes
            : (productManifest.environment.apiRootUrl || productManifest.environment.appRootUrl) +
            "/" +
            productManifest.environment.apiEndpoints.routes;

          const routes: RouteData = await httpGet<RouteData>(url);
          routeData = parseRouting(productManifest, routes);
        }
        if (routeData) {
          route = [...route, routeData.parentRoute];
          routeMap = routeData.routeMap;
        }
      }
      catch (error) {
        console.error(error);
      }
    }));

  store.dispatch(
    setRoute({
      routeMap: routeMap,
      routes: route,
    })
  );
}

function parseRouting(product: Product, config: any): { parentRoute: AppRoute<ProductRouteData>, routeMap: RouteMap[] } {
  const parentRoute: AppRoute<ProductRouteData> = {
    path: product.key,
    element: <Layout />,
    children: [],
  };

  config.forEach(
    (route: RouteData) => {
      try {
        parentRoute.children.push({
          path: route.path,
          element: (
            <ProtectedRoute>
              <AppViewerSkeleton productKey={product.key} />
            </ProtectedRoute>
          ),
        });

        const localPath = product.key + "/" + [route.path].filter((x) => x).join("/");

        routeMap = [
          ...routeMap,
          {
            productKey: product.key,
            productName: product.name === undefined ? product.key : product.name,
            path: "/" + product.key + "/" + route.path,
            title: route.title || nameRoute(route.path),
            contentPathDefinition: route.contentPath,
            contentPathRegex: getPathMatchRegex(product.key, route.contentPath),
            contentPath: route.contentPath,
            localPathDefinition: localPath,
            localPathRegex: getPathMatchRegex(product.key, localPath),
            queryMap: route.queryMap,
            permissions: route.permissions,
            permissionRequirement: route.permissionRequirement,
            popout: route.popout,
            defaultRoute: route.defaultRoute && !route.path.match(/(?:^|\/)\\:/),
          },
        ];
      } catch (error) {
        console.error(error);
      }
    }
  );
  return { parentRoute, routeMap };
}

const nameRoute = (path: string) => {
  return path
    .split("/")
    .reverse()[0]
    .replace(/\\-\\_/g, " ")
    .replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase());
};

let routeMap: RouteMap[] = [];
