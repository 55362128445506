import { Label } from "@medm-ui/label";
import { MultiSelectDropdown, MultiSelectItem } from "@medm-ui/select-combobox";
import { LastVisitedPage, LastVisitedPages } from "models/last-visited-page/last-visited-page";
import moment from "moment";
import { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { getLastVisitedPages } from "services/last-visited-page";
import { getCurrentApplicationUrl, getRouteInfo, hasAccess } from "services/router-helper";
import "./home.css";
import CardsRow from "./homeCardsRow";

export function Home() {
  const unionConfig = useAppSelector((state) => state.unionConfig.unionConfig);
  const permissionSet = useAppSelector((state) => state.permission.permission);
  const menu = useAppSelector((state) => state.menu.menu);
  const routeMap = useAppSelector((state) => state.route.routeMap);
  const [lastVisitedPages, setLastVisitedPages] = useState([] as Array<LastVisitedPages>);
  const [displayedLVP, setDisplayedLVP] = useState(lastVisitedPages);
  const [data, setData] = useState([] as Array<MultiSelectItem>);
  const [lastVisitedData, setlastVisitedData] = useState(null);
  const style = {
    height: "inherit",
    width: "360px",
  };

  useEffect(() => {
    getLastVisitedPages(unionConfig).then((results) => {
      setlastVisitedData(results);
    });
  }, []);

  useEffect(() => {
    const pages = [] as Array<LastVisitedPages>;
    if (lastVisitedData?.length > 0) {
      lastVisitedData.forEach((result: LastVisitedPage) => {
        //Checking if the users is having access to product and product is in menus[]
        if (menu?.length) {
          const access = hasAccess(
            result.applicationName,
            result.visitedURL,
            menu,
            permissionSet,
            routeMap);

          if (access) {
            const product = unionConfig.manifest.find((product) => product.key === result.applicationName);
            if (!product || !Object.prototype.hasOwnProperty.call(product.environments, unionConfig.defaultEnvironment)) {
              return;
            }

            const icon = product?.icon;
            const productName = product?.name;
            const url = getCurrentApplicationUrl(result.visitedURL);
            const pageData = getRouteInfo(url, routeMap);
            if (pageData) {
              // Display select company card only if user is assigned Multiple Company
              if (pageData.productKey === "compliance" && pageData.path === "/compliance/select-company") {
                if (
                  permissionSet[pageData.productKey] !== null &&
                  permissionSet[pageData.productKey] !== undefined &&
                  !permissionSet[pageData.productKey].includes("MULTI-TENANT")
                ) {
                  return;
                }
              }
              const pageName = pageData?.title;
              const index = pages.findIndex((page) => page.key === productName);
              if (index < 0) {
                pages.push({
                  key: productName,
                  pages: [
                    {
                      pageName: pageName,
                      lastOpened: moment.utc(result.visitedDateTime).local().format("DD MMM YYYY, HH:mm"),
                      icon: {
                        icon: icon ? icon : "",
                        color: "#AAAAAA",
                        size: "3x",
                      },
                      url: url,
                      popout: pageData.popout,
                    },
                  ],
                });
              } else {
                pages[index].pages.push({
                  pageName: pageName,
                  lastOpened: moment.utc(result.visitedDateTime).local().format("DD MMM YYYY, HH:mm"),
                  icon: {
                    icon: icon ? icon : "",
                    color: "#AAAAAA",
                    size: "3x",
                  },
                  url: url,
                  popout: pageData.popout,
                });
              }
            }
          }
        }
      });
    }
    setLastVisitedPages(pages);
    setDisplayedLVP(pages);
  }, [menu, lastVisitedData, unionConfig.manifest, routeMap, permissionSet]);

  useEffect(() => {
    const labels: MultiSelectItem[] = [];
    lastVisitedPages?.forEach((page: LastVisitedPages) => {
      if (menu.find((item: any) => item.label === page.key)) {
        if (page.key) {
          labels.push({
            text: page.key,
            value: page.key,
            tagValue: page.key,
            isSelected: false,
          });
        }
      }
    });
    setData(labels);
  }, [lastVisitedPages]);

  const handleMultiselectValueChange = (value: any) => {
    if (value.length > 0) {
      const pages = [] as Array<LastVisitedPages>;
      value.forEach((v: string) => {
        const product = lastVisitedPages.find((page) => page.key === v);
        pages.push(product);
      });
      setDisplayedLVP(pages);
    } else {
      setDisplayedLVP(lastVisitedPages);
    }
  };

  return (
    <>
      <div className="lastVisitedLabelContainer" data-test-id="lastVisitedLabelContainer">
        <Label
          label="Welcome back"
          size="Large"
          type="Header"
          weight="Bold"
          color="#222222"
          className="homePageTitleLabel"
        />
        <Label
          label="Access your financial S&P Global applications in one, connected experience."
          size="Small"
          type="Body"
          weight="Regular"
          color="#666666"
          className="homePageSubtitleLabel"
        />
      </div>
      <div className="selectSection">
        <Label
          label="Your last visited"
          size="Medium"
          type="Body"
          weight="Bold"
          color="#222222"
          className="lastVisitedLabel"
        />
        <MultiSelectDropdown
          data={data}
          id="multiselect"
          data-test-id="multiselect"
          size="medium"
          style={style}
          placeholder="Select an application"
          noOfTagsToDisplay={2}
          preventClose={false}
          filterable={true}
          onValueChange={handleMultiselectValueChange}
        />
      </div>
      <div className="lastVisitedContainer" data-test-id="lastVisitedContainer">
        {displayedLVP &&
          displayedLVP.map((entry: LastVisitedPages) => {
            return (
              <div className="lastVisitedPagesContainer" key={entry.key}>
                <span className="small-card-row">
                  <CardsRow entry={entry} numberOfCards={4} />
                </span>
                <span className="medium-card-row">
                  <CardsRow entry={entry} numberOfCards={5} />
                </span>
                <span className="large-card-row">
                  <CardsRow entry={entry} numberOfCards={6} />
                </span>
              </div>
            );
          })}
      </div>
    </>
  );
}
