import { createSlice } from "@reduxjs/toolkit";
import { PermissionSet } from "models";

export const permissionSlice = createSlice({
  name: "permission",
  initialState: {
    permission: null as PermissionSet,
  },
  reducers: {
    setPermission: (state, action) => {
      return {
        ...state,
        permission: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPermission } = permissionSlice.actions;

export default permissionSlice.reducer;
