import axios from "axios";
import { PermissionList, PermissionSet, Product, UnionConfig } from "models";
import { ProductKeys } from "models/product-keys";
import { setPermission } from "redux/features/permission";
import { httpGet } from "services/http/http.service";
import { PathHelper } from "services/path-helper";
import { SessionStorageService } from "services/storage-service";
import store from "store";

export async function loadPermission(unionConfig: UnionConfig): Promise<PermissionSet> {

  let permissionData: PermissionSet = {};

  if (!unionConfig.manifest) {
    return permissionData;
  }

  await Promise.allSettled(
    unionConfig.manifest.map(async (product) => {
      try {
        permissionData = await fetchProductPermissions(product, permissionData);
      } catch (error) {
        permissionData[product.key] = null;
      }
    })
  );

  store.dispatch(setPermission(permissionData));
  return permissionData;
}

export function parsePermissionResponse(productKey: string, result: any): PermissionList {
  if (result.items) {
    result = resultGetter(result, productKey);
  }
  return result;
}

function resultGetter(result: any, productKey: string) {
  if (result.items.length > 1 && result.items[0].permissions) {
    const settings: any = SessionStorageService.getItem("multi-tenant-selection", "multi-tenant-selection");
    if (settings && settings[productKey]) {
      const match = result.items.find((x: any) => isTenantMatch(settings[productKey], x));
      if (match) {
        result = [...match.permissions, "MULTI-TENANT"];
      } else {
        result = ["MULTI-TENANT"];
      }
    } else {
      result = ["MULTI-TENANT"];
    }
  } else if (result.items.length == 1 && result.items[0].permissions) {
    result = result.items[0].permissions;
  } else {
    result = result.items;
  }
  return result;
}

function isTenantMatch(criteria: { [x: string]: any }, candidate: { [x: string]: any }): boolean {
  for (const key in criteria) {
    if (candidate[key] !== criteria[key]) {
      return false;
    }
  }
  return true;
}

export async function isRestricted(config: UnionConfig, productKey: string): Promise<string> {
  try {
    const apiUrl = config.adminApiUrl + "/UserProductPermissions/" + productKey;
    return await axios.get(apiUrl).then((response) => {
      return response.headers.isrestricted;
    });
  } catch (error: any) {
    console.log(error);
  }
}

async function fetchProductPermissions(product: Product, permissionData: PermissionSet): Promise<PermissionSet> {
  if (!product.environment.apiEndpoints.permissions) {
    return permissionData;
  }

  const url = PathHelper.isAbsolutePath(product.environment.apiEndpoints.permissions)
    ? product.environment.apiEndpoints.permissions
    : (product.environment.apiRootUrl || product.environment.appRootUrl) +
    "/" +
    product.environment.apiEndpoints.permissions;

  const complianceRequestTimeout = 20000
  const data = await httpGet(url, product.key === ProductKeys.compliance ? complianceRequestTimeout : undefined);
  const permissionLists = parsePermissionResponse(product.key, data);
  permissionData[product.key] = permissionLists.length ? permissionLists : [];
  return permissionData;
}
