import { List } from "@medm-ui/list";
import { ListItem } from "@medm-ui/list/dist/esm/lib/list.types";
import { TenantItem } from "components/layout/header/tenant-switcher/tenant-switcher.types";
import { UnionModal } from "components/shared/modal";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import "./tenant-selection.css";

export function TenantSelection(props: any) {
    const tenant = useAppSelector((state) => state.tenant);
    const [tenantInfo, setTenantInfo] = useState<ListItem[]>([]);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
       if(tenant.items.length > 0){
            const tenantList: ListItem[] = tenant?.items.map((item: TenantItem) => ({
                id: item.tenantId as any,
                value: item.tenantUniqueName,
                text: item.displayName,
            }));
            setTenantInfo(tenantList);
        } else { 
            navigate("/");
        }
    }, [tenant]);


    const onTenantSelection = (Item: ListItem) => {
        const selectTenant: TenantItem = { 'tenantId': Item.id.toString(), 'tenantUniqueName': Item.value, 'displayName': Item.text };
        sessionStorage.setItem('selectedTenant', JSON.stringify(selectTenant));
        navigate(location.state.url);
    }

    const handleClick = () => {
        window.location.href = `mailto:${"MEDM-Support@ihsmarkit.com"}`;
    };

    return (<>
        {tenantInfo.length > 0 &&
            <UnionModal
                id={props.id}
                title="Login to EDM Tenant"
                size="small"
                displayCloseIcon={false}
                links={[{
                    displayName: "Please contact support for enquiries.",
                    onClick: () => handleClick()
                }]}
                actionsLayout={"start"}
            >
                <div className="tenant-list-selection">
                    <List id={"tenantlist"} searchDelay={0} type={"actionable"} widthSize="432px" onListClick={(Item: ListItem) => onTenantSelection(Item)} listData={tenantInfo} placeHolder="Search by name..."></List>
                </div>
            </UnionModal >}
    </>);
}
